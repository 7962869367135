.tutorial-container {
  white-space: pre-wrap!important;
  padding-bottom: 40px;
}

.tutorial-text {
    font-family: Merriweather Sans,sans-serif;
}

.tutorial-header {
  padding-bottom: 20px;
  padding-top: 20px;
  color: #162C53;
/*  text-decoration: underline;*/
  font-size: 38px;
}
.tutorial-header-small {
  padding-bottom: 20px;
  padding-top: 20px;
  color: #162C53;
  text-decoration: underline;
  font-size: 28px;
}
.tutorial-subheader {
/*  padding-top: 10px;*/
    padding-bottom: 10px;
  color: #162C53;
/*  text-decoration: underline;*/
  font-size: 24px;
}

.toc-container {
    background: whitesmoke;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
}
.tutorial-section {
    margin-top: 20px;
    margin-bottom: 20px;
}

.tutorial-div {
  padding: 20px;
  border-radius: 10px;
  background: whitesmoke;

}