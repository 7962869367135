.header {
  height: 150px;
  width: 100%;
  text-align: center;
  background-color: #F5F5F5;
}

.header-text{
  font-size: 42px;
  padding: 0px;
  margin: 0px;
  color: #162C53;
  text-decoration: none;
}

.header-text a{
  text-decoration: none;
}

.header-text a:visited  {
  color: #162C53;
  text-decoration: none;
}

#arm-logo{
  padding-top: 10px;
}

#doe-logo {
  display: table-cell;
  vertical-align: middle;
}

.center-div
{
    display: inline-block;
}

.menu-bar{
  text-align: center;
  height: 60px;
  width: 100%;
  background-color: #162C53;
}

.menu-bar-bottom{
  text-align: center;
  height: 5px;
  width: 100%;
  background-color: #0F1A2E;
}

.dropdown-selected-indicator{
  cursor: pointer;
  width: 245px;
  height: 5px;
}

.menu-bar-item{
  display: table; 
  width: 245px;
  height: 100%;
}

.menu-bar-item-text{
  display: table-cell;
  height: 100%;
  color: white;
  vertical-align: middle;
  margin: 0px;
  font-size: 22px;
}

.fixed-width-container{
  display: flex;
  margin: auto;
  width: 980px;
  height: 100%;
}


/* Dropdown Button */
.dropbtn {
  cursor: pointer;
  background-color: #162C53;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  height: 100%;
  width: 100%;
      font-size: 18px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  width: 245px;
  height: 100%;
  float: left;
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #0F1A2E;
  min-width: 245px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: white;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #162C53;}

/* Show the dropdown menu on hover */
#tools-dropdown:hover #tools-dropdown-content {display: block;}
#tools-indicator:hover #tools-dropdown-content {display: block;}

#tools-dropdown:hover #tools-indicator{background-color: #1DBB73;}
#tools-indicator:hover {background-color: #1DBB73;}

#documentation-dropdown:hover #documentation-dropdown-content {display: block;}
#documentation-indicator:hover #documentation-dropdown-content {display: block;}

#documentation-dropdown:hover #documentation-indicator{background-color: #1DBB73;}
#documentation-indicator:hover {background-color: #1DBB73;}

#reports-dropdown:hover #reports-dropdown-content {display: block;}
#reports-indicator:hover #reports-dropdown-content {display: block;}

#reports-dropdown:hover #reports-indicator{background-color: #1DBB73;}
#reports-indicator:hover {background-color: #1DBB73;}





.news-feed{
  margin-top: 0px;
  width: 100%;
  float: left;
}
.news-feed-header {
  padding-top: 20px;
  color: #162C53;
  text-decoration: underline;
  font-size: 36px;
}

.news-feed-entry{
  width: 100%;
  border-left: 5px solid #0F1A2E;
  margin-top: 40px;
  margin-left: 5px;
}

.news-feed-entry-image-container{
    max-height: 300px;
    max-width: 300px;
    width: 100%;
    height: 100%;
    min-height: 200px;
    display: flex;
    align-items: center;
    float: left;
    overflow: hidden;
}

.news-feed-entry-text-container{
  padding-top: 10px;
  padding-left: 20px;
  float: right;
  width: 100%;
  max-height: 350px;
}


.news-feed-entry-image{
    overflow: hidden;
    background-color: grey;
/*    margin-left: 20px;*/
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: table-cell;
}

.news-feed-entry-title{
  margin-bottom: 0px;
  font-size: 22px;
  color: #0F1A2E;
}
.news-feed-entry-date{
  font-size: 14px;
  color: #707070;
}
.news-feed-entry-content{
  font-size: 14px;
  font-family: 'Merriweather Sans', sans-serif;
}

/*@media only screen and (max-width: 992px) and (min-width: 768px) {

  .contacts{
    width: 250px!important;
  }
  .sidebar-pic{
    width: 250px!important;
  }
  .quick-links {
    width: 250px!important;
  }
}*/

@media only screen and (min-width: 992px) {

  .sidebar {
    width: 250px!important;
  }
  .contacts{
    float: none!important;
    width: 250px!important;
  }
  .sidebar-pic{
    width: 250px!important;
  }
  .quick-links {
    width: 250px!important;
  }
  .about-blurb {
    width: 250px!important;
  }
}

.sidebar{
  width: 100%;
  float: right;
  height: 100%;
}

.about-blurb {
  margin-top: 40px;
  font-size: 14px;
  width: 100%;
  background-color: #0F1A2E;
  color: white;
  padding: 20px;
  border-radius: 5px;
}

.about-blurb-title {
  font-size: 24px;
  color: #1DBB73;
}

.about-blurb-text{
  font-family: 'Merriweather Sans', sans-serif;
}

.contacts{
  display: inline-block;
  height: 340px;
  width: 175px;
  background-color: #0F1A2E;
  margin-top: 20px;
  float: right;
  border-radius: 5px;
/*  margin: 20px;*/
}

.contacts-title{
  color: #1DBB73;
  font-size: 24px;
  margin: 10px 0px 0px 20px;
}

.contact-container{
  height: 238px;
  width: 185px;
  padding: 20px;
  padding-left: 40px;
}

.contact-container a{
  text-decoration: none;
}

.contact-entry{
  height: 40px;
  width: 100px;
  border-left: 3px solid #1DBB73;
  padding-left: 10px;
  margin-bottom: 10px;
}

.contact-entry p{
  margin: 0px;
}

.contact-name{
  font-size: 16px;
  color: white;
}

.contact-role{
  font-size: 12px;
  color: #1DBB73;
  width: 100px;
}

.sidebar-pic{
  width: calc(100% - 185px);
  max-width: 480px;
  height: auto;
  background-color: grey;
  margin-top: 20px;
  float: left;
  overflow: hidden;
  border-radius: 5px;
}
.sidebar-pic-img{
  width: 100%;
  height: 100%;
}

.quick-links{
  width: 175px;
  height: 150px;
  outline: 2px solid #C7C7C7;
  margin-top: 20px;
  float: left;
}
.quick-links-link{
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 40px;
  color: #1DBB73;
}
.quick-links-link a:visited{
  color: #1DBB73;
}
.quick-links-title{
  color: #0F1A2E;
  font-size: 18px;
  padding: 10px 0px 0px 20px;
}
.footer{
  flex-direction: column;
  display: flex;
  margin-top: 40px;
  width: 100%;
  height: 100%;
  background-color: #F5F5F5;
}
.footer-divider{
  height: 10px;
  width: 100%;
  background-color:#0F1A2E;
}
.labs-img{
  margin: auto;
}