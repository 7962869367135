 .faq-container {
    white-space: pre-wrap!important;
    padding-bottom: 40px;
 }

 .card {
    font-family: Merriweather Sans,sans-serif;
 }

  .card-header {
    color: #162C53;
    border-bottom: none;
    padding: 1.00rem 1.50rem;
  }

  .card-header:hover {
    color: #0F1A2E;
    cursor: pointer;
    border-left: 4px solid #1DBB73;
  }

 .card-header button {
    text-align: left!important;
    font-size: 20px;
 }

 .faq-header {
  padding-top: 20px;
  color: #162C53;
/*  text-decoration: underline;*/
  font-size: 34px;
}

 .faq-subheader {
  color: #162C53;
  text-decoration: underline;
  font-size: 26px;
  padding-bottom: 10px;
  padding-bottom: 20px;
}
 .faq-smallsubheader {
  color: #162C53;
  text-decoration: none;
  font-size: 20px;
  padding-bottom: 10px;
  padding-top: 20px;
}