.about-container {
	height: 100%;
}

.about-text {
	margin-top: 10px;
 	font-family: 'Merriweather Sans', sans-serif;
 }

.about-header {
  padding-top: 20px;
  color: #162C53;
/*  text-decoration: underline;*/
  font-size: 34px;
/*  margin-bottom: 20px;*/
  width: 100%;
}

.about-subheader {
	color: #162C53;
    text-decoration: underline;
    font-size: 26px;
    width: 100%;
    margin-top: 20px;
/*    padding-bottom: 10px;
    padding-bottom: 20px;*/
}