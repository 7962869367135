/* Override bootstrap styling for Navbar */

.bg-dark {
    background-color: #162C53!important;
}

.navbar-dark {
    font-size: 22px!important;
    border-bottom: 4px solid #0F1A2E!important;
}
.dropdown-menu {
    margin-top: 8px!important;
    border-radius: 0px!important;
    width: 100%!important;
    background: #0F1A2E!important;
    border: none!important;
    border-top: 4px solid #1DBB73!important;
}
.dropdown-menu a{
    color: white!important;
}
.dropdown-menu a:hover{
    background: #162C53!important;
    color: white!important;
}
.dropdown-item.active {
    background-color: #1DBB73!important;
}
.dropdown-item:active {
    background-color: #1DBB73!important;
}
.dropdown-divider {
    border-top: 2px solid #162c53!important;
}
/*.navbar-dark a{
    color: #F5F5F5!important;
}*/